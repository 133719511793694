@tailwind base;
@tailwind components;
@tailwind utilities;
html{
  scroll-behavior: smooth;
}
#id{
  scroll-behavior: smooth;
}
.icn{
  width: 25px;
  height: 25px;
  font-size: 20px;
  display: block;
  background-size: cover;
}
.i-facebook{
  background-image: url("../public/assets/facebook.svg");
}
.i-github{
  background-image: url("../public/assets/github.svg");
}
.i-instagram{
  background-image: url("../public/assets/instagram.svg");
}
.i-linkedin{
  background-image: url("../public/assets/linkedin.svg");
}

.tooltip-div:hover > div{   
  visibility: visible !important;
}